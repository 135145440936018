/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */


import { window } from 'browser-monads';
import engine from 'store/src/store-engine';
import storage from 'store/storages/localStorage';
import defaults from 'store/plugins/defaults';
import * as Loads from 'react-loads';
import React from 'react';


const store = engine.createStore([storage], [defaults]);

window.jocrfStore = store;

const config = {
  cacheProvider: {
    get: key => store.get(key),
    set: (key, val) => store.set(key, val),
    reset: () => store.clearAll()
  }
};

export const wrapRootElement = ({ element }) => (
  <Loads.Provider config={config}>
    {element}
  </Loads.Provider>
);
