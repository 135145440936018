// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accounting-js": () => import("./../src/pages/accounting.js" /* webpackChunkName: "component---src-pages-accounting-js" */),
  "component---src-pages-client-add-js": () => import("./../src/pages/client-add.js" /* webpackChunkName: "component---src-pages-client-add-js" */),
  "component---src-pages-client-info-index-js": () => import("./../src/pages/client-info/index.js" /* webpackChunkName: "component---src-pages-client-info-index-js" */),
  "component---src-pages-client-index-js": () => import("./../src/pages/client/index.js" /* webpackChunkName: "component---src-pages-client-index-js" */),
  "component---src-pages-clients-js": () => import("./../src/pages/clients.js" /* webpackChunkName: "component---src-pages-clients-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-report-preview-index-js": () => import("./../src/pages/report-preview/index.js" /* webpackChunkName: "component---src-pages-report-preview-index-js" */),
  "component---src-pages-research-index-js": () => import("./../src/pages/research/index.js" /* webpackChunkName: "component---src-pages-research-index-js" */),
  "component---src-pages-research-report-js": () => import("./../src/pages/research/report.js" /* webpackChunkName: "component---src-pages-research-report-js" */),
  "component---src-pages-resend-client-info-js": () => import("./../src/pages/resend/client-info.js" /* webpackChunkName: "component---src-pages-resend-client-info-js" */),
  "component---src-pages-resend-confirmation-js": () => import("./../src/pages/resend/confirmation.js" /* webpackChunkName: "component---src-pages-resend-confirmation-js" */),
  "component---src-pages-resend-report-js": () => import("./../src/pages/resend/report.js" /* webpackChunkName: "component---src-pages-resend-report-js" */),
  "component---src-pages-user-logout-js": () => import("./../src/pages/user/logout.js" /* webpackChunkName: "component---src-pages-user-logout-js" */)
}

